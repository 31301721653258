import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./contact-section.css"; // Import the custom CSS file
import { useNavigate } from "react-router-dom";
import {  useStatus } from '../../context/StatusContext';

const initialFormData = {
  paginaWebEmpresa: '',
  redesSocialesEmpresa: '',
  nombreApellido: '',
  email: '',
  phone: '',
};

const initialErrors = Object.keys(initialFormData).reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {});

const ContactSection = () => {

  const [isPoliticPrivacyAccepted, setIsPoliticPrivacyAccepted] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);
  const [touched, setTouched] = useState({});

  const validateField = (name, value) => {
    const validators = {
      paginaWebEmpresa: value => value.trim() !== '',
      redesSocialesEmpresa: value => value.trim() !== '',
      nombreApellido: value => value.trim() !== '',
      email: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      phone: value => /^\+57 \d{3} \d{3} \d{4}$/.test(value)
    };
    return validators[name] ? validators[name](value) : false;
  };

  const formatPhoneNumber = (value) => {
    
    const digits = value.replace(/^\+57|\s/g, '').slice(0, 10); 

    if (digits.length <= 3) {
      return `+57 ${digits}`;
    } else if (digits.length <= 6) {
      return `+57 ${digits.slice(0, 3)} ${digits.slice(3)}`;
    }
    return `+57 ${digits.slice(0, 3)} ${digits.slice(3, 6)} ${digits.slice(6)}`;

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newPhoneValue = '';
    let isValid;

    if (name === "phone") {
      newPhoneValue = formatPhoneNumber(value).substring(0, 19);

      isValid = validateField(name, newPhoneValue);
      setFormData(prevData => ({ ...prevData, [name]: newPhoneValue }));

    } else {
      isValid = validateField(name, value);
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }

    setTouched(prev => ({ ...prev, [name]: true }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: !isValid }));
  };

const navigate = useNavigate();

const { STATUS, setStatus } = useStatus();
  const handleSubmit = async (event) => {
    event.preventDefault();

      setStatus(STATUS.DATA_TO_ADD_LEAD);
    
    navigate("/citacion", { state: formData });
      resetForm();
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
    setTouched({});
    setIsPoliticPrivacyAccepted(false);
  };

  const allFieldsValid = Object.values(errors).every(error => !error);
  const canSubmit = isPoliticPrivacyAccepted && allFieldsValid;

  return (
    <section className="h-50" id="contact">
      <div className="image-container-contact overflow-hidden">
        <img src="/images/blur.png" alt="Background Blur" className="w-100" />
      </div>
      <Row className="d-flex justify-content-center align-items-center px-6">
        <Col
          md={6}
          className="d-flex align-items-center px-0 "
        >
          <div className="w-100 px-5 px-md-0">
            <h1 className="large-text d-none d-md-block">
              No seas <br />
              solo viral,
              <br />
              <span>se</span>{" "}
              <span className="gradient-text fst-italic pe-5">rentable</span>
            </h1>
            {/* Mobile design */}
            <h1 className="large-text ps-4 d-block d-md-none">
              No seas <br/>
              solo viral,<br/> 
              se{" "}
              <span className="gradient-text fst-italic pe-5">rentable</span>
            </h1>
            <p className="fs-2 lh-1">
            Nosotros convertimos tu producto <br/> de high ticket en ventas reales.</p>
          </div>
        </Col>
        <Col xs={12} md={6} className="pe-0 px-2">
          <div className="form-container pb-5 mb-0 mt-5 mt-md-0 w-100 px-5 px-md-0">
            <h1 className="custom-text-agenda">Agenda tu cita de 30 minutos gratis</h1>
            <Form onSubmit={handleSubmit} className="w-100">
              <Form.Group controlId="formName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="nombreApellido"
                  value={formData.nombreApellido}
                  isInvalid={errors.nombreApellido && touched.nombreApellido}
                  onChange={handleChange}
                  onBlur={() => setTouched({ ...touched, nombreApellido: true })}
                  className="mb-4 border border-1 border-black"
                />
                {errors.nombreApellido && touched.nombreApellido && <Form.Control.Feedback type="invalid"></Form.Control.Feedback>}
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Número de teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  maxLength={20}
                  value={formData.phone}
                  isInvalid={errors.phone && touched.phone}
                  onChange={handleChange}
                  onBlur={() => setTouched({ ...touched, phone: true })}
                  className="mb-4 border border-1 border-black"
                />
                {errors.phone && touched.phone && <Form.Control.Feedback type="invalid"></Form.Control.Feedback>}
              </Form.Group>


              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  isInvalid={errors.email && touched.email}
                  onChange={handleChange}
                  onBlur={() => setTouched({ ...touched, email: true })}
                  className="mb-4 border border-1 border-black"
                />
                {errors.email && touched.email && <Form.Control.Feedback type="invalid"></Form.Control.Feedback>}
                {errors.email && touched.email && <Form.Control.Feedback type="invalid"></Form.Control.Feedback>}
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formWebsite">
                      <Form.Label>Página web de la empresa</Form.Label>
                      <Form.Control
                        type="text"
                        name="paginaWebEmpresa"
                        value={formData.paginaWebEmpresa}
                        isInvalid={errors.paginaWebEmpresa && touched.paginaWebEmpresa}
                        onChange={handleChange}
                        onBlur={() => setTouched({ ...touched, paginaWebEmpresa: true })}
                        className="mb-4 border border-1 border-black"
                      />
                      {errors.paginaWebEmpresa && touched.paginaWebEmpresa && <Form.Control.Feedback type="invalid"></Form.Control.Feedback>}
                      {errors.paginaWebEmpresa && touched.paginaWebEmpresa && <Form.Control.Feedback type="invalid"></Form.Control.Feedback>}
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Group controlId="formSocialMedia">
                      <Form.Label>Redes sociales de la empresa</Form.Label>
                      <Form.Control
                        type="text"
                        name="redesSocialesEmpresa"
                        value={formData.redesSocialesEmpresa}
                        isInvalid={errors.redesSocialesEmpresa && touched.redesSocialesEmpresa}
                        onChange={handleChange}
                        onBlur={() => setTouched({ ...touched, redesSocialesEmpresa: true })}
                        className="mb-4 border border-1 border-black"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox" className="mb-4">
                <Form.Check
                  type="checkbox"
                  value={isPoliticPrivacyAccepted}
                  onChange={() => setIsPoliticPrivacyAccepted(!isPoliticPrivacyAccepted)}
                  label="Acepto la política de privacidad"
                />
                
              </Form.Group>
              <div className="d-flex flex-column flex-md-row justify-content-center">
                <Button
                  variant="dark"
                  type="submit"
                  disabled={!canSubmit}
                  className="w-100 mb-2 me-md-2"
                >
                  Agenda tu cita
                  <span className="ms-2">
                    <svg
                      width="26"
                      height="20"
                      viewBox="0 0 26 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 1.25L24.25 10M24.25 10L15.5 18.75M24.25 10L1.75 10"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Button>
                <Button
                  variant="outline-dark"
                  className="border-2 mt-2 ms-3 mt-md-0 d-none d-md-block"
                  style={{ width: "50%" }}
                  onClick={() =>
                    document
                      .getElementById("services")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  Conoce más
                </Button>

                {/* Modal */}
                {/* <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Cita agendada</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Nos estaremos comunicando contigo dentro de poco!</Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal> */}

              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </section>


  );
};

export default ContactSection;
