import { formatDateToUTC } from "../../utils/dateUtils";

/**
 * Sends a POST request to add a new lead to the CRM system.
 *
 * @param {string} nombre - The title of the lead.
 * @param {string} email - The email address of the lead.
 * @param {string} date - The date associated with the lead.
 * @param {string} paginaWeb - The website associated with the lead.
 * @param {string} redesSociales - The social media associated with the lead.
 * @return {boolean} True if the lead was added successfully, false otherwise.
 */
export const FetchAddLead = async (nombre, email, date, paginaWeb, redesSociales, phone) => {
    try {
        const response = await fetch('https://colcargollc.bitrix24.com/rest/16998/7bk29abdu7ycl2t2/crm.lead.add.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'cookie': 'qmb=0.',
          },
          body: JSON.stringify({
            fields: {
              TITLE: nombre,
              EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
              PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
              WEB: paginaWeb,
              UF_CRM_1723158181126: paginaWeb,
              UF_CRM_1723158230783: redesSociales,
              UF_CRM_1724794396674: formatDateToUTC(date),
              "ASSIGNED_BY_ID": "2",//Jose Garavito
              SOURCE_ID: "Solicitud de cotización Somos Tu Team",
            }
          }),
        });
  
        if (!response.ok) throw new Error('Network response was not ok');
        await response.json();
        return true;
      } catch (error) {
        console.error("There was an error adding the lead:", error);
      }
    };
